import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageLayout from "../components/layout-page"
import BlogPostDescription from "../components/Blog/BlogPostDescription"

const BlogDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 1rem 0 1rem 0;
`

const Blog = ({ data }) => {
   const posts = data.allMdx.edges
   return (
      <Layout>
         <SEO title="Blog" />
         <PageLayout title="Blog">
            <Img
               sizes={data.headerImage.childImageSharp.fluid}
               style={{ margin: "0 auto" }}
            />
            <BlogDescriptionContainer>
               {
                  posts.map(({ node }) => (
                     <BlogPostDescription key={node.fields.slug} node={node} />
                  ))
               }
            </BlogDescriptionContainer>
       </PageLayout>
     </Layout>
   )
}

export default Blog;

export const pageQuery = graphql`
   query {
      site {
         siteMetadata {
         title
         }
      }
      allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
         edges {
         node {
            excerpt
            fields {
               slug
            }
            frontmatter {
               date(formatString: "MMMM DD, YYYY")
               title
               description
            }
         }
         }
      }
      headerImage: file(
         relativePath: { eq: "10-lb-rogue-weight-plate-near-people-gathered-703016.jpg" }
      ){
         childImageSharp {
            fluid(maxWidth: 1260, quality: 90) {
               ...GatsbyImageSharpFluid_noBase64
            }
         }
      }
   }
`
