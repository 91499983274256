import React from "react"

import { Link } from "gatsby"
import styled from "styled-components"

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

const BlogDescriptionCard = styled(Card)`
   display: flex;
   margin: 16px 8px;
   background-color: ${props => props.theme.offWhite};
   color: ${props => props.theme.black};
   @media(min-width: 768px){
      width: 85%;
   }
   @media(max-width: 767px){
      width: 100%;
   }
`
const BlogDescriptionButton = styled(Button)`
  color: ${props => props.theme.black};
  border: 1px solid ${props => props.theme.black};
`

const BlogDescription = ({node}) => {
   const title = node.frontmatter.title || node.fields.slug
   const description = node.frontmatter.description || node.excerpt
   const date = node.frontmatter.date
   return (
      <BlogDescriptionCard >
         <CardContent>
            <Link to={`blog${node.fields.slug}`} style={{textDecoration: 'none', color:'inherit'}}>
            <Typography component="h2" variant="h5" color="inherit">
                  {title}
            </Typography>
               </Link>
            <Typography variant="subtitle1">
               {date}
            </Typography>
            <Typography variant="subtitle1" paragraph>
               {description}
            </Typography>
            <Link to={`blog${node.fields.slug}`} style={{boxShadow: 'none', textDecoration: 'none', color:'inherit'}}>
               <BlogDescriptionButton variant='outlined'>
                  Continue reading...
               </BlogDescriptionButton>
            </Link>
         </CardContent>
      </BlogDescriptionCard>
   )
}

export default BlogDescription;